// import { Trans } from 'react-i18next'
import { ExclamationCircleFilled, CheckCircleFilled } from '@ant-design/icons'
import Image from 'next/image'
import React, { FC, ReactElement, useState, useEffect } from 'react'
import { GoogleLogin } from 'react-google-login'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { LoginStyle, LoginGlobalStyle } from 'src/@legacy/style/styled/LoginStyle.style'
import { SocialStyle } from 'src/@legacy/@core/style/styled/antd/Button.style'
import { Row, Col } from 'src/@legacy/@core/components/share/ANTD/Grid'
import { AppLink } from 'src/@legacy/@core/components/share/AppLink'
import { LINK_URL_ROOT, LINK_URL_FORGOT_PASSWORD, LINK_URL_CHAT_WITH_SUPPORT, LINK_URL_REGISTER } from 'src/@legacy/utilities/LinkURL'
import { RESOURCE_IMAGE_FREEC_LOGO_EN, RESOURCE_IMAGE_FREEC_LOGO_VN } from 'src/@legacy/utilities/Constant'
import { RESOURCE_IMAGE_FREEC_LOGO } from 'src/@legacy/utilities/Constant'
import { Form } from 'src/@legacy/@core/components/share/ANTD/Form'
import { Button } from 'src/@legacy/@core/components/share/ANTD/Button'
import { Input } from 'src/@legacy/@core/components/share/ANTD/Input'
import { TRACKING_ID_LOGIN_LOGIN } from 'src/@legacy/utilities/TrackingIDConstant'
import { Divider } from 'src/@legacy/@core/components/share/ANTD/Divider'
import AsyncAction from 'src/@legacy/components/AsyncAction'
import ChangeLanguageDropdown from 'src/@legacy/@core/components/ChangeLanguageDropdown'
import Footer from 'src/@legacy/@core/layout/Footer'
import { Trans } from 'react-i18next'
import { RegisterStyle } from 'src/@legacy/@core/style/styled/RegisterStyle.style'
import { ANTDInputStyle } from 'src/@legacy/@core/style/base/antd-input-style'
import {
  TRACKING_ID_CANDIDATE_REGISTER_FACEBOOK_BUTTON,
  TRACKING_ID_LOGIN_GOOGLE,
  TRACKING_ID_LOGIN_LINKEDIN
} from 'src/@legacy/@core/utilities/TrackingIDConstant'
import { getCookie } from 'src/@legacy/@optimal-page/utillities/Cookies'
import useAppRouter from 'src/@legacy/@optimal-page/hooks/useAppRouter'
import IconGoogle from '../assets/icon_google.svg'
import IconFacebook from '../assets/icon_facebook.svg'
import IconLinkedin from '../assets/icon_linkedin.svg'
import env from 'src/@legacy/utilities/env'
import { FacebookProvider, Login } from 'react-facebook-sdk'

const LoginComponent: FC<{
  verifyData?: { email: string; user_id: number }
  nonFieldError?: string | ReactElement
  isVerify?: boolean
  loginIn?: boolean
  message?: string
  onFinish?: (arr1) => void
  onSendVerify?: (arr1, arr2, fnc) => void
  responseGoogle?: (arr1) => Promise<any>
  googleSocialErrorHandler?: (arr1) => void
  linkedInLogin: () => Promise<void>
  loading: boolean
  loginFacebook: (data: any) => Promise<boolean | void>
}> = (props) => {
  const {
    verifyData,
    nonFieldError,
    isVerify,
    loginIn,
    message,
    onFinish,
    onSendVerify,
    responseGoogle,
    googleSocialErrorHandler,
    linkedInLogin,
    loading,
    loginFacebook
  } = props
  const { translate, currentLanguage, changeLanguage } = useTranslation()
  const router = useAppRouter()
  const [count, setCount] = useState(0)
  const [isDisable, setIsDisable] = useState(false)

  useEffect(() => {
    if (!getCookie('X_LANGUAGE')) changeLanguage('vi')
    const countDown = setInterval(() => {
      if (count > 0) {
        setCount(count - 1)
      }
      if (count === 0) {
        setIsDisable(false)
      }
    }, 1000)
    return () => clearInterval(countDown)
  })

  const handleAfterResendEmail = () => {
    setIsDisable(true)
    setCount(60)
  }

  return (
    <>
      <style jsx>{LoginStyle}</style>
      <style jsx global>
        {LoginGlobalStyle}
      </style>
      <style jsx global>
        {SocialStyle}
      </style>
      <style jsx>{RegisterStyle}</style>
      <style jsx global>
        {ANTDInputStyle}
      </style>

      <div className="wrapper-container">
        <div className="login-page register-page">
          <Row justify="center">
            <Col xs={24} md={20}>
              <Row gutter={[{ xs: 0, lg: 16 }, 0]}>
                <Col xs={24} lg={14} xl={12}>
                  <div className="d-flex align-items-center justify-content-center flex-column register-form-wrapper">
                    <Row justify="center" className="w-100">
                      <Col xs={22} xl={20}>
                        <div className="logo">
                          <AppLink keepUTM href={LINK_URL_ROOT}>
                            <a className="header-logo-img">
                              {currentLanguage === 'en' ? (
                                <Image quality={100} src={RESOURCE_IMAGE_FREEC_LOGO} alt="freec-platform" width={100} height={40} />
                              ) : (
                                <Image quality={100} src={RESOURCE_IMAGE_FREEC_LOGO} alt="freec-platform" width={100} height={40} />
                              )}
                            </a>
                          </AppLink>
                        </div>
                        <div className="login-form" style={{ paddingTop: 24 }}>
                          <Form name="login_form" layout="vertical" onFinish={onFinish}>
                            <Row align="middle" justify="space-between">
                              <Col span={12} className="tab-header xs:pb-0 md:pb-4 active">
                                <button type="button" className="btn-active">
                                  {translate('page_login:login')}
                                </button>
                              </Col>
                              <Col span={12} className="tab-header xs:pb-0 md:pb-4">
                                <button
                                  type="button"
                                  className="btn-inActive"
                                  onClick={() => router.push(LINK_URL_REGISTER, LINK_URL_REGISTER, { keepUTM: true })}>
                                  {translate('page_login:create_your_freec_account')}
                                </button>
                              </Col>
                            </Row>
                            <div className="form-body">
                              <div>
                                {message && (
                                  <Row justify="center">
                                    <Col>
                                      <div className="message-notice">
                                        <ExclamationCircleFilled style={{ color: '#6d4fed' }} />
                                        <span className="message-text mr-2">{message}</span>
                                        {isVerify && (
                                          <Button
                                            disabled={isDisable}
                                            className="btn-resend_email"
                                            onClick={() => onSendVerify(verifyData.email, verifyData.user_id, handleAfterResendEmail)}>
                                            {count > 0 && <span className="mr-1">{count}</span>}
                                            {translate('page_login:resend_email')}
                                          </Button>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                              </div>
                              <Row gutter={[16, 8]}>
                                <Col xs={8} md={8} lg={8}>
                                  <AsyncAction
                                    action={linkedInLogin}
                                    render={({ run, loading }) => {
                                      return (
                                        <Button
                                          id={TRACKING_ID_LOGIN_LINKEDIN}
                                          onClick={run}
                                          disabled={loading}
                                          loading={loading}
                                          size="large"
                                          className="btn-social">
                                          <Image quality={100} src={IconLinkedin} alt="linkedin icon" width={24} height={24} />
                                        </Button>
                                      )
                                    }}
                                  />
                                </Col>
                                <Col xs={8} md={8} lg={8}>
                                  <GoogleLogin
                                    clientId={env.PUBLIC_GOOGLE_APP_ID}
                                    render={(renderProps) => (
                                      <Button
                                        id={TRACKING_ID_LOGIN_GOOGLE}
                                        onClick={renderProps.onClick}
                                        disabled={renderProps.disabled}
                                        size="large"
                                        className="btn-social">
                                        <Image quality={100} src={IconGoogle} alt="google icon" width={24} height={24} />
                                      </Button>
                                    )}
                                    buttonText="Login"
                                    onSuccess={responseGoogle}
                                    onFailure={googleSocialErrorHandler}
                                    cookiePolicy={'single_host_origin'}
                                  />
                                </Col>
                                <Col xs={8} md={8} lg={8}>
                                  <FacebookProvider appId={env.PUBLIC_FACEBOOK_APP_ID}>
                                    <Login
                                      scope="email"
                                      onResponse={loginFacebook}
                                      render={({ isWorking, onClick }) => (
                                        <Button
                                          id={TRACKING_ID_CANDIDATE_REGISTER_FACEBOOK_BUTTON}
                                          loading={isWorking}
                                          className="btn-social"
                                          size="large"
                                          // disabled
                                          onClick={onClick}>
                                          <Image src={IconFacebook} alt="facebook icon" width={24} height={24} />
                                        </Button>
                                      )}
                                    />
                                  </FacebookProvider>
                                </Col>
                              </Row>
                              <Divider style={{ borderColor: '#e8e8e8', color: '#7a7c82' }} plain className="divider-login">
                                {translate('page_login:or_login')}
                              </Divider>
                              <Form.Item
                                label={translate('label:email_address')}
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: translate('form:validate_required')
                                  },
                                  {
                                    max: 250,
                                    message: translate('form:validate_max_length', {
                                      number: 250
                                    })
                                  },
                                  {
                                    type: 'email',
                                    message: translate('form:validate_email_format')
                                  }
                                ]}>
                                <Input placeholder="email@example.com" size="large" maxLength={250} id="login_form_email" />
                              </Form.Item>
                              <Form.Item
                                label={translate('label:password')}
                                name="password"
                                rules={[
                                  {
                                    required: true,
                                    message: translate('form:validate_required')
                                  },
                                  {
                                    min: 6,
                                    message: translate('form:validate_range_length', {
                                      min: 6,
                                      max: 20
                                    })
                                  }
                                ]}>
                                <Input.Password
                                  size="large"
                                  placeholder={translate('label:placeholder_password')}
                                  id="login_form_password"
                                  maxLength={20}
                                />
                              </Form.Item>
                              <div className="error-text">{nonFieldError && <div>{nonFieldError}</div>}</div>
                              <AppLink href={LINK_URL_FORGOT_PASSWORD}>
                                <a className="link-forgot-pw" id="link_forgot_password">
                                  {translate('label:forgot_password')}
                                </a>
                              </AppLink>
                              <Form.Item>
                                <Button
                                  id={TRACKING_ID_LOGIN_LOGIN}
                                  loading={loginIn}
                                  type="primary"
                                  className="btn-submit w-100"
                                  htmlType="submit"
                                  size="large">
                                  {translate('button:login')}
                                </Button>
                              </Form.Item>
                            </div>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                    <Row justify="center" className="w-100 mt-3">
                      <Col xs={22} xl={20}>
                        <div className="d-flex xs:flex-column md:flex-row align-items-center justify-content-space-between">
                          <p className="text-bottom">
                            {translate('label:you_need_support')}
                            <AppLink href={LINK_URL_CHAT_WITH_SUPPORT}>
                              <a className="link-bottom" id="link_create_account">
                                {translate('label:contact_us_with_chat')}
                              </a>
                            </AppLink>
                          </p>
                          <ChangeLanguageDropdown className="xs:mt-4 md:mt-0" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={0} lg={10} xl={12}>
                  <div className="d-flex flex-column block-right">
                    <p className="block-right_title">{translate('page_register:what_you_will_get')}</p>
                    <p className="detail">
                      <span className="icon mr-2">
                        <CheckCircleFilled />
                      </span>
                      <span>
                        <Trans i18nKey="page_register:unlimited_free_job_post">
                          <span className="high-light" />
                        </Trans>
                      </span>
                    </p>
                    <p className="detail">
                      <span className="icon mr-2">
                        <CheckCircleFilled />
                      </span>
                      <span>{translate('page_register:easily_work_with_your_hiring')}</span>
                    </p>
                    <p className="detail">
                      <span className="icon mr-2">
                        <CheckCircleFilled />
                      </span>
                      <span>{translate('page_register:share_job_quickly')}</span>
                    </p>
                    <Divider className="mt-0 mb-3" />
                    <p className="detail">{translate('page_register:get_you_faster')}</p>
                    <p className="detail">
                      <Trans i18nKey="page_register:join_orther_company" values={{ number: process.env.NEXT_PUBLIC_NUMBER_COMPANIES }}>
                        <span className="high-light" />
                      </Trans>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default LoginComponent
