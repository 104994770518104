import { useStoreTokenAndFetchUser } from 'src/@legacy/@core/hooks/CurrentUserHooks'
import { useAppRouter } from 'src/@legacy/@core/hooks/UseAppRouter'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { handleErrorAPIFromRequest, LIST_URL } from '../../@core/utilities/CatchApiError'
import React, { ReactElement, useState } from 'react'
import { submitLoginAPI, submitResendEmailAPI, submitLoginWithSocialAPI } from 'src/@legacy/service/api/login.api'
import LoginView from './components/LoginView'
import { useAuthenData } from 'src/@legacy/store/AuthData'
import { toStringFromQuery } from 'src/@legacy/utilities/common'
import { LINK_URL_TOKEN_NOT_FOUND, LINK_URL_COMPANY_JOB_LIST } from 'src/@legacy/utilities/LinkURL'
import { readToken } from '../../@core/utilities/JWTToken'
import RegisterSocialModal from 'src/@legacy/components/RegisterSocialModal'
import { submitRegisterSocialAPI } from 'src/@legacy/service/api/register.api'
import { useDimention } from 'src/@legacy/@core/hooks/UseDimention'
import { openNotificationWithIcon } from 'src/@legacy/@core/utilities/Notification'
import * as Mixpanel from '../../utilities/Mixpanel'
import useLinkedinLogin from './hooks/useLinkedinLogin'
import { getlinkedinCode } from 'src/utilities/SocialUtilsFunction'

const LoginWrapper = () => {
  const { verifyMessage, setVerifyMessage, setVerifyData, verifyData } = useAuthenData()
  const { translate, currentLanguageNumber } = useTranslation()
  const router = useAppRouter()
  const [nonFieldError, setNoneFieldError] = useState<string | ReactElement>()
  const [isVerify, setIsVerify] = useState(false)
  const [loginIn, setLoginIn] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const message = verifyMessage.message
  const fallBackUrl = router.query.redirect
  const storeTokenAndFetchUser = useStoreTokenAndFetchUser()
  const [loadingRegister, setLoadingRegister] = useState(false)
  const [dataPayload, setDataPayload] = useState<any>()
  const { fromProduct } = useDimention()

  /* ----------- API ----------- */

  const onFinish = (values) => {
    setLoginIn(true)

    return submitLoginAPI(values)
      .then((response) => {
        return storeTokenAndFetchUser(response.data.token).then(() => {
          const jwt = readToken(response.data.token)
          router.push(fallBackUrl ? decodeURIComponent(toStringFromQuery(fallBackUrl)) : LINK_URL_COMPANY_JOB_LIST(jwt.company_id))
        })
      })
      .catch((error) => {
        handleErrorAPIFromRequest({
          error,
          router,
          page: LIST_URL.LOGIN,
          callbackAction: (errorText) => setNoneFieldError(errorText),
          callbackType: (type) => {
            if (['NOT_VERIFIED_ACCOUNT', 'NOT_VERIFIED_USER'].includes(type)) {
              const response = error?.response || {}
              const { data } = response
              setVerifyMessage({ message: translate('notification:you_have_not_verified_yet') })
              setVerifyData({
                email: values.email.toLowerCase(),
                user_id: data?.messages?.user_id
              })
              setIsVerify(true)
              localStorage.setItem('AUTHENTICATION_TOKEN', '')
            }
          }
        })
      })
      .finally(() => {
        setLoginIn(false)
      })
  }
  const formData2Store = (values) => ({
    ...values,
    email: dataPayload?.email.toLowerCase() || '',
    full_name: dataPayload.full_name || '',
    photo_url: dataPayload.photo_url || '',
    provider: dataPayload.provider || 1
  })
  const onFinishRegister = (data) => {
    setLoadingRegister(true)
    const params = formData2Store(data)

    submitRegisterSocialAPI({ values: params, fromProduct, currentLanguageNumber })
      .then((response) => {
        return storeTokenAndFetchUser(response.data.token).then(() => {
          const jwt = readToken(response.data.token)
          Mixpanel.alias(jwt.user_id.toString())
          Mixpanel.track(Mixpanel.EVENTS.SIGNUP)
          router.push(fallBackUrl ? decodeURIComponent(toStringFromQuery(fallBackUrl)) : LINK_URL_COMPANY_JOB_LIST(jwt.company_id))
        })
      })
      .catch((error) => {
        if (!error.response || (error.response && error.response.status === 422)) {
          if (error.response && error.response.data?.messages?.msgs[0] === 'Name has already been taken') {
            setNoneFieldError(translate('notification:name_exist'))
            openNotificationWithIcon('error', translate('notification:name_exist'), '')
          } else if (error.response && error.response.data?.messages?.msgs[0] === 'Email has already been taken') {
            setNoneFieldError(translate('notification:validate_email_unique'))
            openNotificationWithIcon('error', translate('notification:validate_email_unique'), '')
          }
          return
        } else {
          handleErrorAPIFromRequest({
            error,
            page: LIST_URL.REGISTER_CANDIDATE,
            callbackAction: (errorText, errorDescription) => openNotificationWithIcon('error', errorText, errorDescription),
            callbackType: (type) => {
              if (type === 'INVALID_TOKEN') {
                router.push(LINK_URL_TOKEN_NOT_FOUND)
              }
            }
          })
        }
        throw error
      })
      .finally(() => {
        setLoadingRegister(false)
        setOpen(false)
      })
  }

  const onSendVerify = (email, user_id, setTimeInterval) => {
    return submitResendEmailAPI({ email, user_id })
      .then((response) => {
        setTimeInterval && setTimeInterval()
        if (response.status && response.status !== 200) {
          setIsVerify(false)
          setVerifyMessage({
            message: translate('notification:you_have_verified_your_email')
          })
          router.push('/login')
        }
      })
      .catch((error) => {
        handleErrorAPIFromRequest({
          error,
          page: LIST_URL.REGISTER_RESEND,
          callbackAction: (errorText, errorDescription) => openNotificationWithIcon('error', errorText, errorDescription),
          callbackType: (type) => {
            if (type === 'INVALID_TOKEN') {
              router.push(LINK_URL_TOKEN_NOT_FOUND)
            }
          }
        })
        throw error
      })
  }

  const socialLogin = (token, provider) => {
    setLoading(true)
    // setOpen(!open)
    return submitLoginWithSocialAPI({ token, provider })
      .then((response) => {
        const jwt = readToken(response.data.token)
        // add tracking
        Mixpanel.identifyByToken(response.data.token)
        Mixpanel.trackLogin(Mixpanel.LoginMethod.SOCIAL)
        return storeTokenAndFetchUser(response.data.token).then(() =>
          router.push(fallBackUrl ? decodeURIComponent(toStringFromQuery(fallBackUrl)) : LINK_URL_COMPANY_JOB_LIST(jwt.company_id))
        )
      })
      .catch((error) => {
        if (error.response.data.messages.errors && error.response.data.messages.errors[0] === 'ACCOUNT_NOT_EXIST') {
          setOpen(true)
          setDataPayload(error.response.data.messages.payload)
        } else {
          handleErrorAPIFromRequest({
            error,
            page: LIST_URL.LOGIN_WITH_SOCIAL,
            callbackAction: (errorText, errorDescription) => openNotificationWithIcon('error', errorText, errorDescription)
          })
        }
      })

      .finally(() => setLoading(false))
  }
  /* ----------- FUNCTION HANDLER ----------- */

  const responseGoogle = (response) => {
    if (response.accessToken) {
      return socialLogin(response.accessToken, 1)
    } else {
      return Promise.reject('cannot retrieve access token')
    }
  }
  const googleSocialErrorHandler = (err) => {
    throw err
  }

  /* ----------- RENDER ----------- */

  const { linkedInLogin } = useLinkedinLogin({
    onLoginCode: (code) => {
      return socialLogin(getlinkedinCode(code), 4)
    }
  })
  const loginFacebook = (data) => socialLogin(data?.tokenDetail?.accessToken, 0)

  return (
    <>
      <LoginView
        verifyData={verifyData}
        nonFieldError={nonFieldError}
        isVerify={isVerify}
        loginIn={loginIn}
        message={message}
        onFinish={onFinish}
        loading={loading}
        onSendVerify={onSendVerify}
        responseGoogle={responseGoogle}
        linkedInLogin={linkedInLogin}
        googleSocialErrorHandler={googleSocialErrorHandler}
        loginFacebook={loginFacebook}
      />
      {open && (
        <RegisterSocialModal
          open={open}
          onOk={() => {
            setOpen(false)
            setLoading(false)
          }}
          onFinish={onFinishRegister}
          loading={loadingRegister}
          dataPayload={dataPayload}
        />
      )}
    </>
  )
}

export default LoginWrapper
